import React, { useState } from 'react'
import { BoxButtons, BoxColors, BoxOptions, BoxTema, ButtonHelp, ButtonReset, ButtonSave, Container, Item, ItemSelect, SelectCustom, Subtitle, Title, Tooltip } from './styled'

import IconReset from "../../../../assets/imgs/icon-reset.svg";
import IconSeta from "../../../../assets/imgs/seta-back.svg";
import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import { useDispatch, useSelector } from 'react-redux';
import { TextAreaCustom } from '../form-email/styled';
import { CounterItemsCircle } from '../box-config-tema/styled';
import { InputTextAreaCustom } from '../box-config-email/styled';
import { useEffect } from 'react';

import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { getTranslation } from '../../../../translations';

import { IoIosArrowBack } from "react-icons/io";
import { MenuOption, ItemOption, Main, BarSubmenu, BarSubmenuItem } from '../bar-config-product-cores/styled';

import IconImgProduct from "../../../../assets/imgs/icons-sidebar/submenu-product.svg";
import IconImgProductInativo from "../../../../assets/imgs/icons-sidebar/submenu-product-inativo.svg";
import IconImgEmail from "../../../../assets/imgs/icons-sidebar/submenu-email-inativo.svg";
import IconImgEmailAtivo from "../../../../assets/imgs/icons-sidebar/submenu-email-ativo.svg";
import IconImgHome from "../../../../assets/imgs/icon-homepage.svg";
import IconImgHomeAtivo from "../../../../assets/imgs/icon-homepage-active.svg";
import IconImgTeste from "../../../../assets/imgs/teste-00.svg";
import { useHistory, useLocation } from 'react-router';
import { InputCustom } from '../bar-config-avaliar-config/styled';
import { Posicao } from '../bar-config-home-config/styled';
import IconLink from "../../../../assets/imgs/icon-link.svg";


const BarConfigProductAjustes = () => {

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const history = useHistory()

    const dispatch = useDispatch()
    const terms_use_default = translation.thema.boxConfigTema.termosUsoTexto

    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)

    const [max_width, set_max_width] = useState("")

    const selectedMenu = useLocation();

    const layout_stars_centro = JSON.stringify({
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "margin": "5px 0px"
    })


    useEffect(() => {
        try {
            if (configControl?.layout_configs != "null") {
                const data = JSON.parse(configControl?.layout_configs)
                set_max_width(data.max_width)
            } else {
                set_max_width("")
            }
        } catch (error) {

        }
    }, [configControl])


    const visibleMenu = (opc) => {
        switch (opc) {
            case "0":
                //set_visible_button_ask(1)
                dispatch({ type: "CONFIG_CONTROL_SET", payload: { visible_button_ask: 1, visible_button_review: 1 } })
                //set_visible_button_review(1)
                dispatch({ type: "CONFIG_CONTROL_ABA_SET", payload: "reviews" })

                break;
            case "1":
                //set_visible_button_ask(0)
                //set_visible_button_review(1)
                dispatch({ type: "CONFIG_CONTROL_SET", payload: { visible_button_ask: 0, visible_button_review: 1 } })
                dispatch({ type: "CONFIG_CONTROL_ABA_SET", payload: "reviews" })
                break;
            case "2":
                //set_visible_button_ask(1)
                //set_visible_button_review(0)
                dispatch({ type: "CONFIG_CONTROL_SET", payload: { visible_button_ask: 1, visible_button_review: 0 } })
                dispatch({ type: "CONFIG_CONTROL_ABA_SET", payload: "questions" })
                break;
            default:
                break;
        }
    }




    const saveTheme = () => {

        dispatch({
            type: "SAGA_UPDATE_CONFIG", payload: {
                id: config.data?.id,
                no_review_star: configControl.no_review_star,
                visible_button_review: configControl.visible_button_review,
                visible_button_ask: configControl.visible_button_ask,
                visible_date_review: configControl.visible_date_review,
                itens_per_page: configControl.itens_per_page,
                layout: configControl.layout,
                layout_question: configControl.layout_question,
                layout_form: configControl.layout_form,
                layout_form_ask: configControl.layout_form_ask,
                //terms_use: configControl.terms_use,
                font: configControl.font,
                order_reviews: configControl.order_reviews,
                order_asks: configControl.order_asks,
                layout_stars: configControl.layout_stars,
                resume_reviews: configControl.resume_reviews,
                layout_resume: configControl.layout_resume,
                auto_approve: configControl.auto_approve,

                border_card_radius: configControl.border_card_radius,
                border_card_size: configControl.border_card_size,

                position_star_review: configControl.position_star_review,
                position_star_review_product: configControl.position_star_review_product,

                no_review_store: configControl.no_review_store,
                //request_email_date: configControl.request_email_date

                script_public_language: configControl.script_public_language,
                layout_widget: configControl.layout_widget,
                layout_configs: getLayoutConfigs()
            }
        })

    }

    const getLayoutConfigs = () => {
        if (configControl.layout_widget == "modelo04") {
            try {
                if (configControl?.layout_configs != "null") {
                    const data = JSON.parse(configControl?.layout_configs)
                    data["max_width"] = max_width
                    return JSON.stringify(data)
                } else {
                    const newData = {
                        color_star_active: "#FACC15",
                        color_star_inactive: "#CCCCCC",
                        color_verified: "#000000",
                        max_width: max_width
                    }

                    return JSON.stringify(newData)
                }
            } catch (error) {
                return configControl?.layout_configs
            }
        } else {
            return configControl.layout_configs
        }
    }

    const getDefaultTheme = () => {
        const themaDefault = {
            id: config.data.id,
            no_review_star: 1,
            visible_button_review: 1,
            visible_button_ask: 1,
            visible_date_review: 1,
            itens_per_page: 20,
            layout: "lily",
            layout_question: "modelo4",
            layout_form: "modelo1",
            layout_form_ask: "modelo1",
            //terms_use: terms_use_default,
            font: "Poppins",
            order_reviews: "",
            order_asks: "",
            layout_stars: "",
            resume_reviews: 0,
            layout_resume: "modelo01",
            auto_approve: 1,

            border_card_radius: "4px",
            border_card_size: "1px",
            position_star_review: "center",
            position_star_review_product: "left",
            no_review_store: 0,
            //request_email_date: 20
            script_public_language: "",
            layout_widget: "default"
        }
        dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefault })
    }


    const isSaveProductConfig = () => {

        if (
            configControl.no_review_star == config.data.no_review_star &&
            configControl.visible_button_review == config.data.visible_button_review &&
            configControl.visible_button_ask == config.data.visible_button_ask &&
            configControl.visible_date_review == config.data.visible_date_review &&
            configControl.itens_per_page == config.data.itens_per_page &&
            configControl.layout == config.data.layout &&
            configControl.layout_question == config.data.layout_question &&
            configControl.layout_form == config.data.layout_form &&
            configControl.layout_form_ask == config.data.layout_form_ask &&
            //configControl.terms_use == config.data.terms_use &&
            configControl.font == config.data.font &&
            configControl.order_reviews == config.data.order_reviews &&
            configControl.order_asks == config.data.order_asks &&
            configControl.layout_stars == config.data.layout_stars &&
            configControl.resume_reviews == config.data.resume_reviews &&
            configControl.layout_resume == config.data.layout_resume &&
            configControl.auto_approve == config.data.auto_approve &&

            configControl.border_card_radius == config.data.border_card_radius &&
            configControl.border_card_size == config.data.border_card_size &&
            configControl.position_star_review == config.data.position_star_review &&
            configControl.position_star_review_product == config.data.position_star_review_product &&

            configControl.no_review_store == config.data.no_review_store
            //configControl.script_public_language == config.script_public_language
            //configControl.request_email_date == config.data.request_email_date

        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_PRODUCT_CONFIG_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_PRODUCT_CONFIG_SET", payload: false })
            return false
        }
    }

    const getLanguage = () => {
        if (JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es") {
            return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language + "-mx"
        } else {
            return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language
        }
    }

    return (
        <Main>
            {/* <BarSubmenu>
                <BarSubmenuItem
                    style={{ position: "relative" }}
                    active={true}
                    onClick={() => { isSaveProductConfig() && history.push("/personalizar/tema") }}
                >
                    {selectedMenu.pathname.slice(13) == "/" || selectedMenu.pathname.slice(13) == "" || selectedMenu.pathname.slice(13) == "/tema" ?
                        <img width={24} src={IconImgProduct} /> :
                        <img width={24} src={IconImgProductInativo} />}

                    <Tooltip>
                        Página do produto
                    </Tooltip>
                </BarSubmenuItem>

                <BarSubmenuItem
                    active={false}
                    style={{ position: "relative" }}
                    onClick={() => { isSaveProductConfig() && history.push("/personalizar/email") }}
                >
                    {selectedMenu.pathname.slice(13) == "/email" ?
                        <img width={24} src={IconImgEmailAtivo} /> :
                        <img width={24} src={IconImgEmail} />}

                    <Tooltip>
                        E-mail
                    </Tooltip>
                </BarSubmenuItem>

                <BarSubmenuItem
                    active={false}
                    style={{ position: "relative" }}
                    onClick={() => { isSaveProductConfig() && history.push("/personalizar/home") }}
                >
                    {selectedMenu.pathname.slice(13) == "/home" ?
                        <img width={24} src={IconImgHomeAtivo} /> :
                        <img width={24} src={IconImgHome} />}

                    <Tooltip>
                        Página do inicial
                    </Tooltip>

                </BarSubmenuItem>


            </BarSubmenu> */}

            <Container>
                <Title>
                    {/* <span onClick={() => { isSaveProductConfig() && history.push("/personalizar") }}>
                        <IoIosArrowBack />
                    </span> */}
                    <label > {translation.thema.boxConfigTema.title} </label>
                </Title>

                <MenuOption>
                    <ItemOption active={false} onClick={() => {
                        isSaveProductConfig() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "produto/cores" })
                    }}>
                        {translation.thema.boxConfigTema.cores}
                    </ItemOption>
                    <ItemOption active={true} onClick={() => {
                        isSaveProductConfig() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "produto/config" })
                    }}>
                        {translation.thema.boxConfigTema.avancado}
                    </ItemOption>
                </MenuOption>

                {/* <BoxOptions>
                    <label>E-mail </label>
                    <SelectCustom name="select" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { request_email_date: e.target.value } }) }}                    >
                        <option value="0" selected={configControl.request_email_date == 0 ? true : false}>Nunca</option>
                        <option value="1" selected={configControl.request_email_date == 1 ? true : false} >01 dias após a compra</option>
                        <option value="2" selected={configControl.request_email_date == 2 ? true : false} >02 dias após a compra</option>
                        <option value="3" selected={configControl.request_email_date == 3 ? true : false} >03 dias após a compra</option>
                        <option value="4" selected={configControl.request_email_date == 4 ? true : false} >04 dias após a compra</option>
                        <option value="5" selected={configControl.request_email_date == 5 ? true : false} >05 dias após a compra</option>
                        <option value="6" selected={configControl.request_email_date == 6 ? true : false} >06 dias após a compra</option>
                        <option value="7" selected={configControl.request_email_date == 7 ? true : false} >07 dias após a compra</option>
                        <option value="8" selected={configControl.request_email_date == 8 ? true : false} >08 dias após a compra</option>
                        <option value="9" selected={configControl.request_email_date == 9 ? true : false} >09 dias após a compra</option>
                        <option value="10" selected={configControl.request_email_date == 10 ? true : false} >10 dias após a compra</option>
                        <option value="11" selected={configControl.request_email_date == 11 ? true : false} > 11 dias após a compra</option>
                        <option value="12" selected={configControl.request_email_date == 12 ? true : false} >12 dias após a compra</option>
                        <option value="13" selected={configControl.request_email_date == 13 ? true : false} >13 dias após a compra</option>
                        <option value="14" selected={configControl.request_email_date == 14 ? true : false} >14 dias após a compra</option>
                        <option value="15" selected={configControl.request_email_date == 15 ? true : false} >15 dias após a compra</option>
                        <option value="16" selected={configControl.request_email_date == 16 ? true : false} >16 dias após a compra</option>
                        <option value="17" selected={configControl.request_email_date == 17 ? true : false} >17 dias após a compra</option>
                        <option value="18" selected={configControl.request_email_date == 18 ? true : false} >18 dias após a compra</option>
                        <option value="19" selected={configControl.request_email_date == 19 ? true : false} >19 dias após a compra</option>
                        <option value="20" selected={configControl.request_email_date == 20 ? true : false} >20 dias após a compra</option>
                        <option value="21" selected={configControl.request_email_date == 21 ? true : false} >21 dias após a compra</option>
                        <option value="28" selected={configControl.request_email_date == 28 ? true : false} >28 dias após a compra</option>
                        <option value="30" selected={configControl.request_email_date == 30 ? true : false} >30 dias após a compra</option>
                        <option value="35" selected={configControl.request_email_date == 35 ? true : false} >35 dias após a compra</option>
                        <option value="42" selected={configControl.request_email_date == 42 ? true : false} >42 dias após a compra</option>
                        <option value="49" selected={configControl.request_email_date == 49 ? true : false} >49 dias após a compra</option>
                        <option value="56" selected={configControl.request_email_date == 56 ? true : false} >56 dias após a compra</option>
                        <option value="63" selected={configControl.request_email_date == 63 ? true : false} >63 dias após a compra</option>
                        <option value="70" selected={configControl.request_email_date == 70 ? true : false} >70 dias após a compra</option>
                    </SelectCustom>
                </BoxOptions> */}

                {configControl?.product?.canonical_url && JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "nuvemshop" ?
                    <BoxOptions>
                        <Posicao>
                            <div>
                                <div
                                    onClick={() => {
                                        window.open(`${configControl?.product?.canonical_url}?editor_lily_review=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}&language=${getLanguage()}&location=review`)
                                        // if (JSON.parse(localStorage.getItem("depoimentos@login")).integration?.url != null)
                                        //     window.open(`https://${JSON.parse(localStorage.getItem("depoimentos@login")).integration.url}?editor_lily_review=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}&language=${getLanguage()}`)
                                    }}
                                >
                                    <label onClick={() => { }} >
                                        {translation.thema.boxConfigHome.btnEditor}
                                    </label>
                                    <img onClick={() => { }} src={IconLink} />
                                </div>
                            </div>
                            <label style={{ textAlign: "center", fontSize: "9px" }}>
                                {translation.thema.configs.definaEspacoAvaliacoes}
                            </label>
                        </Posicao>
                    </BoxOptions> : null}


                <BoxOptions>
                    <ItemSelect>
                        <label>Layout:</label>
                        <SelectCustom name="select_layout_reviews" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_widget: e.target.value } }) }}                        >
                            <option value="default" selected={configControl.layout_widget == "default" ? true : false}>{translation.thema.boxConfigTema.padrao}</option>
                            <option value="modelo01" selected={configControl.layout_widget == "modelo01" ? true : false}>Modelo01</option>
                            <option value="modelo02" selected={configControl.layout_widget == "modelo02" ? true : false}>Modelo02</option>
                            <option value="modelo03" selected={configControl.layout_widget == "modelo03" ? true : false}>Modelo03</option>

                            <option value="modelo04" selected={configControl.layout_widget == "modelo04" ? true : false}>Modelo04</option>
                        </SelectCustom>
                    </ItemSelect>
                    <ItemSelect>
                        <label>{translation.thema.boxConfigTema.modeloAvaliacoes}:</label>
                        {configControl.layout_widget == "modelo04" ?
                            <SelectCustom name="select_layout_reviews" onChange={(e) => { }}                        >
                                <option value="" selected>Padrão</option>
                            </SelectCustom> :
                            <SelectCustom name="select_layout_reviews" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout: e.target.value } }) }}                        >
                                {configControl.layout_widget != "modelo02" &&
                                    <>
                                        <option value="lily" selected={configControl.layout == "lily" ? true : false}>Lily</option>
                                        <option value="lily2" selected={configControl.layout == "lily2" ? true : false}>Lily 2</option>
                                        <option value="lily3" selected={configControl.layout == "lily3" ? true : false}>Lily 3</option>
                                    </>
                                }
                                <option value="dropi" selected={configControl.layout == "dropi" ? true : false} >Dropi</option>
                                <option value="dropi2" selected={configControl.layout == "dropi2" ? true : false} >Dropi 2</option>
                                <option value="dropi3" selected={configControl.layout == "dropi3" ? true : false} >Dropi 3</option>

                                {configControl.layout_widget != "modelo02" &&
                                    <>
                                        <option value="tiktok" selected={configControl.layout == "tiktok" ? true : false} >Tik Tok</option>
                                        <option value="insta" selected={configControl.layout == "insta" ? true : false} >Instagram</option>
                                        <option value="youtube" selected={configControl.layout == "youtube" ? true : false} >Youtube</option>
                                        <option value="lily-large" selected={configControl.layout == "lily-large" ? true : false} >{translation.thema.configs.linha}</option>
                                    </>}
                                {/* <option value="home1" selected={configControl.layout == "home1" ? true : false} >Homepage</option> */}
                            </SelectCustom>
                        }

                    </ItemSelect>

                    <ItemSelect>
                        <label>{translation.thema.boxConfigTema.modeloPerguntas}:</label>
                        {configControl.layout_widget == "modelo04" ?
                            <SelectCustom name="select_layout_reviews" onChange={(e) => { }}                        >
                                <option value="" selected>Padrão</option>
                            </SelectCustom> :
                            <SelectCustom name="select_layout_questions" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_question: e.target.value } }) }}                        >
                                <option value="modelo1" selected={configControl.layout_question == "modelo1" ? true : false}>{translation.thema.boxConfigTema.modelo} 1</option>
                                <option value="modelo2" selected={configControl.layout_question == "modelo2" ? true : false}>{translation.thema.boxConfigTema.modelo} 2</option>
                                <option value="modelo3" selected={configControl.layout_question == "modelo3" ? true : false}>{translation.thema.boxConfigTema.modelo} 3</option>
                                <option value="modelo4" selected={configControl.layout_question == "modelo4" ? true : false}>{translation.thema.boxConfigTema.modelo} 4</option>
                            </SelectCustom>}
                    </ItemSelect>


                    <ItemSelect>
                        <label>{translation.thema.boxConfigTema.telaAvaliar}:</label>
                        {configControl.layout_widget == "modelo04" ?
                            <SelectCustom name="select_layout_reviews" onChange={(e) => { }}                        >
                                <option value="" selected>Padrão</option>
                            </SelectCustom> :
                            <SelectCustom name="select_layout_form" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_form: e.target.value } }) }}                        >
                                <option value="modelo1" selected={configControl.layout_form == "modelo1" ? true : false}>{translation.thema.boxConfigTema.padrao}</option>
                                <option value="modelo2" selected={configControl.layout_form == "modelo2" ? true : false}>{translation.thema.boxConfigTema.modelo} 2</option>
                            </SelectCustom>}
                    </ItemSelect>


                    <ItemSelect>
                        <label>{translation.thema.boxConfigTema.telaPerguntar}:</label>
                        {configControl.layout_widget == "modelo04" ?
                            <SelectCustom name="select_layout_reviews" onChange={(e) => { }}                        >
                                <option value="" selected>Padrão</option>
                            </SelectCustom> :
                            <SelectCustom name="select_layout_form" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_form_ask: e.target.value } }) }}                        >
                                <option value="modelo1" selected={configControl.layout_form_ask == "modelo1" ? true : false}>{translation.thema.boxConfigTema.padrao}</option>
                                <option value="modelo2" selected={configControl.layout_form_ask == "modelo2" ? true : false}>{translation.thema.boxConfigTema.modelo} 2</option>
                            </SelectCustom>}
                    </ItemSelect>

                    {/* <ItemSelect>
                        <label >Máximo largura {"(px)"}</label>
                        <InputCustom
                            type={"number"}
                            value={"1024"}
                            placeholder={"Largura do widget"}
                            onChange={(e) => {
                                //dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_config_title_comment: e.target.value } })
                            }}
                        />
                    </ItemSelect> */}

                    {configControl.layout_widget == "modelo04" ?
                        <ItemSelect>
                            <label>{translation.thema.boxConfigTema.fonte}:</label>
                            <SelectCustom name="select_theme_reviews" onChange={(e) => { }}                        >
                                {/* <option value="" selected>Rubik</option> */}
                                <option value={"Rubik"} selected={configControl.homereviews_font_title == "Rubik" ? true : false}>Rubik</option>
                                <option value={"DM Sans"} selected={configControl.homereviews_font_title == "DM Sans" ? true : false}>DM Sans</option>
                            </SelectCustom>
                        </ItemSelect> :

                        <ItemSelect>
                            <label>{translation.thema.boxConfigTema.fonte}:</label>
                            <SelectCustom name="select_theme_reviews" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { font: e.target.value } }) }}                        >
                                <option value="Poppins" selected={configControl.font == "Poppins" ? true : false}>Poppins</option>
                                <option value="Courier" selected={configControl.font == "Courier" ? true : false} >Courier</option>
                                <option value="Times" selected={configControl.font == "Times" ? true : false} >Times</option>
                                <option value="Roboto" selected={configControl.font == "Roboto" ? true : false} >Roboto</option>
                                <option value={"Georgia"} selected={configControl.font == "Georgia" ? true : false}>Georgia</option>
                                <option value={"Oswald"} selected={configControl.font == "Oswald" ? true : false}>Oswald</option>
                                <option value={"Montserrat"} selected={configControl.font == "Montserrat" ? true : false}>Montserrat</option>
                                <option value={"Arial"} selected={configControl.font == "Arial" ? true : false}>Arial</option>
                                <option value={"DM Sans"} selected={configControl.font == "DM Sans" ? true : false}>DM Sans</option>
                            </SelectCustom>
                        </ItemSelect>}

                    {configControl.layout_widget == "modelo04" ?
                        <ItemSelect>
                            <label>{translation.thema.boxConfigTema.paginaProduto}:</label>
                            <SelectCustom name="select_theme_reviews" onChange={(event) => { }}                        >
                                <option value="0" selected>{translation.thema.boxConfigTema.avaliacoesPerguntas}</option>
                            </SelectCustom>
                        </ItemSelect> :
                        <ItemSelect>
                            <label>{translation.thema.boxConfigTema.paginaProduto}:</label>
                            <SelectCustom name="select_theme_reviews" onChange={(event) => { visibleMenu(event.target.value) }}                        >
                                <option value="0" selected={configControl.visible_button_ask == 1 && configControl.visible_button_review == 1 ? true : false}>{translation.thema.boxConfigTema.avaliacoesPerguntas}</option>
                                <option value="1" selected={configControl.visible_button_ask == 0 && configControl.visible_button_review == 1 ? true : false} >{translation.thema.boxConfigTema.somenteAvaliacoes}</option>
                                <option value="2" selected={configControl.visible_button_ask == 1 && configControl.visible_button_review == 0 ? true : false} >{translation.thema.boxConfigTema.somentePerguntas}</option>
                            </SelectCustom>
                        </ItemSelect>}

                    {configControl.layout_widget != "modelo04" && JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "mercadoshop" ?
                        <ItemSelect>
                            <label>{translation.thema.configs.avaliacoesPaginaProduto}:</label>
                            <SelectCustom name="select_theme_reviews" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { no_review_store: e.target.value } }) }}                        >
                                <option value="0" selected={configControl.no_review_store == 0 ? true : false}>{translation.thema.configs.sim}</option>
                                <option value="1" selected={configControl.no_review_store == 1 ? true : false} >{translation.thema.configs.nao}</option>
                            </SelectCustom>
                        </ItemSelect> : null}


                    <ItemSelect>
                        <label>{translation.thema.boxConfigTema.semAvaliacoes}:</label>
                        <SelectCustom name="select_theme_reviews" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { no_review_star: e.target.value } }) }}                        >
                            <option value="0" selected={configControl.no_review_star == 0 ? true : false}>{translation.thema.boxConfigTema.mostrarEstrelas}</option>
                            <option value="1" selected={configControl.no_review_star == 1 ? true : false} >{translation.thema.boxConfigTema.naoMostrarEstrelas}</option>
                        </SelectCustom>
                    </ItemSelect>

                    {configControl.layout_widget != "modelo04" &&
                        <ItemSelect>
                            <label>{translation.thema.boxConfigTema.exibirDatas}:</label>
                            <SelectCustom name="select_theme_reviews" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { visible_date_review: e.target.value } }) }}                        >
                                <option value="1" selected={configControl.visible_date_review == 1 ? true : false}>{translation.thema.boxConfigTema.mostrar}</option>
                                <option value="0" selected={configControl.visible_date_review == 0 ? true : false} >{translation.thema.boxConfigTema.naoMostrar}</option>
                            </SelectCustom>
                        </ItemSelect>}

                    {configControl.layout_widget == "modelo04" ?
                        <ItemSelect>
                            <label>{translation.thema.configs.exibirResumo}</label>
                            <SelectCustom name="select_layout_stars" onChange={(e) => { }}                        >
                                <option value="1" selected>{"Mostrar"}</option>
                            </SelectCustom>
                        </ItemSelect> :
                        <ItemSelect>
                            <label>{translation.thema.configs.exibirResumo}</label>
                            <SelectCustom name="select_layout_stars" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { resume_reviews: e.target.value } }) }}                        >
                                <option value="0" selected={configControl.resume_reviews != 1 ? true : false}>{translation.thema.configs.naoMostrar}</option>
                                <option value="1" selected={configControl.resume_reviews == 1 ? true : false}>{"Mostrar"}</option>
                            </SelectCustom>
                        </ItemSelect>}

                    {configControl.layout_widget == "modelo04" ?
                        <ItemSelect>
                            <label>{translation.thema.configs.modeloResumo}:</label>
                            <SelectCustom name="select_layout_form" onChange={(e) => { }}                        >
                                <option value="modelo01" selected>Padrão</option>
                            </SelectCustom>
                        </ItemSelect> :
                        <ItemSelect>
                            <label>{translation.thema.configs.modeloResumo}:</label>
                            <SelectCustom name="select_layout_form" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_resume: e.target.value } }) }}                        >
                                <option value="modelo01" selected={configControl.layout_resume == "modelo01" ? true : false}>Modelo 01</option>
                                <option value="modelo02" selected={configControl.layout_resume == "modelo02" ? true : false}>Modelo 02</option>
                                <option value="modelo03" selected={configControl.layout_resume == "modelo03" ? true : false}>Modelo 03</option>
                                <option value="modelo04" selected={configControl.layout_resume == "modelo04" ? true : false}>Modelo 04</option>
                                <option value="modelo05" selected={configControl.layout_resume == "modelo05" ? true : false}>Modelo 05</option>
                            </SelectCustom>
                        </ItemSelect>}

                    <ItemSelect>
                        <label>{translation.thema.boxConfigTema.paginacao}:</label>
                        <SelectCustom name="select_theme_reviews" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { itens_per_page: e.target.value } }) }}                        >
                            <option value="4" selected={configControl.itens_per_page == 4 ? true : false} >4 {translation.thema.boxConfigTema.itens} </option>
                            <option value="6" selected={configControl.itens_per_page == 6 ? true : false} >6 {translation.thema.boxConfigTema.itens} </option>
                            <option value="8" selected={configControl.itens_per_page == 8 ? true : false} >8 {translation.thema.boxConfigTema.itens} </option>
                            <option value="10" selected={configControl.itens_per_page == 10 ? true : false} >10 {translation.thema.boxConfigTema.itens} </option>
                            <option value="12" selected={configControl.itens_per_page == 12 ? true : false} >12 {translation.thema.boxConfigTema.itens} </option>
                            <option value="14" selected={configControl.itens_per_page == 14 ? true : false} >14 {translation.thema.boxConfigTema.itens} </option>
                            <option value="16" selected={configControl.itens_per_page == 16 ? true : false} >16 {translation.thema.boxConfigTema.itens} </option>
                            <option value="18" selected={configControl.itens_per_page == 18 ? true : false} >18 {translation.thema.boxConfigTema.itens} </option>
                            <option value="20" selected={configControl.itens_per_page == 20 ? true : false} >20 {translation.thema.boxConfigTema.itens} </option>
                            <option value="30" selected={configControl.itens_per_page == 30 ? true : false} >30 {translation.thema.boxConfigTema.itens} </option>
                            <option value="40" selected={configControl.itens_per_page == 40 ? true : false} >40 {translation.thema.boxConfigTema.itens} </option>
                            <option value="50" selected={configControl.itens_per_page == 50 ? true : false} >50 {translation.thema.boxConfigTema.itens} </option>
                        </SelectCustom>
                    </ItemSelect>

                    <ItemSelect>
                        <label>{translation.thema.boxConfigTema.ordenarAvaliacoes}:</label>
                        <SelectCustom name="select_theme_reviews" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { order_reviews: e.target.value } }) }}                        >
                            <option value="default" selected={configControl.order_reviews == "default" ? true : false} >{translation.thema.boxConfigTema.maisNovas}</option>
                            <option value="tiktok,video,image" selected={configControl.order_reviews == "tiktok,video,image" ? true : false} >Tiktok, Vídeos, {translation.thema.boxConfigTema.imagens}</option>
                            <option value="tiktok,image,video" selected={configControl.order_reviews == "tiktok,image,video" ? true : false} >Tiktok, {translation.thema.boxConfigTema.imagens}, Vídeos</option>
                            <option value="video,tiktok,image" selected={configControl.order_reviews == "video,tiktok,image" ? true : false} >Vídeos, Tiktok, {translation.thema.boxConfigTema.imagens}</option>
                            <option value="video,image,tiktok" selected={configControl.order_reviews == "video,image,tiktok" ? true : false} >Vídeos, {translation.thema.boxConfigTema.imagens}, Tiktok</option>
                            <option value="image,video,tiktok" selected={configControl.order_reviews == "image,video,tiktok" ? true : false} >{translation.thema.boxConfigTema.imagens}, Vídeos, Tiktok</option>
                            <option value="image,tiktok,video" selected={configControl.order_reviews == "image,tiktok,video" ? true : false} >{translation.thema.boxConfigTema.imagens}, Tiktok, Vídeos</option>
                            <option value="positive" selected={configControl.order_reviews == "positive" ? true : false} >{translation.thema.boxConfigTema.avaliacoesMaiores}</option>
                        </SelectCustom>
                    </ItemSelect>

                    <ItemSelect>
                        <label>{translation.thema.boxConfigTema.ordenarPerguntas}:</label>
                        <SelectCustom name="select_theme_reviews" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { order_asks: e.target.value } }) }}                        >
                            <option value="default" selected={configControl.order_asks == "default" ? true : false} >{translation.thema.boxConfigTema.maisNovas}</option>
                            <option value="answered" selected={configControl.order_asks == "answered" ? true : false} >{translation.thema.boxConfigTema.respondidas}</option>
                            <option value="pending" selected={configControl.order_asks == "pending" ? true : false} >{translation.thema.boxConfigTema.naoRespondidas}</option>
                        </SelectCustom>
                    </ItemSelect>

                    {/* <ItemSelect style={{ position: "relative" }}>
                        <label>{translation.thema.boxConfigTema.termosUso}:</label>
                        <InputTextAreaCustom
                            value={configControl.terms_use}
                            rows={6}
                            maxLength="255"
                            placeholder={`${translation.thema.boxConfigTema.modelo}`}
                            onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { terms_use: e.target.value } }) }}
                        />
                        <CounterItemsCircle porc={`${Math.round(configControl.terms_use?.length / 255 * 100)}%`} colorBorder={configControl.terms_use?.length < 255 ? "green" : "red"}>
                            <label>{255 - configControl.terms_use?.length}</label>
                        </CounterItemsCircle>
                    </ItemSelect> */}

                    <ItemSelect>
                        <label>{"Posição das estrelas na coleção"}</label>
                        <SelectCustom name="select_layout_stars" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_stars: e.target.value } }) }}                        >
                            <option value="" selected={configControl.layout_stars != layout_stars_centro ? true : false}>{translation.thema.configs.esquerda}</option>
                            <option value={layout_stars_centro} selected={configControl.layout_stars == layout_stars_centro ? true : false}>{translation.thema.boxConfigTema.centro}</option>
                        </SelectCustom>
                    </ItemSelect>

                    <ItemSelect>
                        <label>{translation.thema.boxConfigTema.posicaoEstrelasReview}</label>
                        <SelectCustom name="position_star_review" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { position_star_review: e.target.value } }) }}                        >
                            <option value="default" selected={configControl.position_star_review == "default" ? true : false}>{translation.thema.boxConfigTema.padrao}</option>
                            <option value="left" selected={configControl.position_star_review == "left" ? true : false}>{translation.thema.configs.esquerda}</option>
                            <option value="center" selected={configControl.position_star_review == "center" ? true : false}>Centro</option>
                            <option value="right" selected={configControl.position_star_review == "right" ? true : false}>{translation.thema.configs.direita}</option>
                        </SelectCustom>
                    </ItemSelect>

                    <ItemSelect>
                        <label>{"Posição das estrelas no produto"}</label>
                        <SelectCustom name="position_star_review_product" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { position_star_review_product: e.target.value } }) }}                        >
                            <option value="left" selected={configControl.position_star_review_product == "left" ? true : false}>{translation.thema.configs.esquerda}</option>
                            <option value="center" selected={configControl.position_star_review_product == "center" ? true : false}>Centro</option>
                            {/* <option value="right" selected={configControl.position_star_review_product == "right" ? true : false}>{translation.thema.configs.direita}</option> */}
                        </SelectCustom>
                    </ItemSelect>

                    <ItemSelect>
                        <label>{translation.thema.configs.avaliacoesAuto}:</label>
                        <SelectCustom name="auto_approve" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { auto_approve: e.target.value } }) }}                        >
                            <option value={1} selected={configControl.auto_approve == 1 ? true : false} >Todas</option>
                            <option value={5} selected={configControl.auto_approve == 5 ? true : false} >5 {translation.thema.configs.estrelas} </option>
                            <option value={4} selected={configControl.auto_approve == 4 ? true : false} >4 {translation.thema.configs.estrelas} ou {translation.thema.configs.mais}</option>
                            <option value={3} selected={configControl.auto_approve == 3 ? true : false} >3 {translation.thema.configs.estrelas} ou {translation.thema.configs.mais}</option>
                            <option value={2} selected={configControl.auto_approve == 2 ? true : false} >2 {translation.thema.configs.estrelas} ou {translation.thema.configs.mais}</option>
                            <option value={0} selected={configControl.auto_approve == 0 ? true : false} >{translation.thema.configs.manual}</option>
                        </SelectCustom>
                    </ItemSelect>

                    {configControl.layout_widget != "modelo04" &&
                        <ItemSelect>
                            <label>{translation.thema.configs.larguraBorda}:</label>
                            <SelectCustom name="auto_approve" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { border_card_size: e.target.value } }) }}                        >
                                <option value={"0px"} selected={configControl.border_card_size == "0px" ? true : false} >0 px</option>
                                <option value={"1px"} selected={configControl.border_card_size == "1px" ? true : false} >1 px</option>
                                <option value={"2px"} selected={configControl.border_card_size == "2px" ? true : false} >2 px</option>
                                <option value={"3px"} selected={configControl.border_card_size == "3px" ? true : false} >3 px</option>
                                <option value={"4px"} selected={configControl.border_card_size == "4px" ? true : false} >4 px</option>
                                <option value={"5px"} selected={configControl.border_card_size == "5px" ? true : false} >5 px</option>
                            </SelectCustom>
                        </ItemSelect>}

                    {configControl.layout_widget != "modelo04" &&
                        <ItemSelect>
                            <label>{translation.thema.configs.arredondamentoBorda}:</label>
                            <SelectCustom name="border_card_radius" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { border_card_radius: e.target.value } }) }}                        >
                                <option value={"none"} selected={configControl.border_card_radius == "none" ? true : false} >0 px </option>
                                <option value={"1px"} selected={configControl.border_card_radius == "1px" ? true : false} >1 px</option>
                                <option value={"2px"} selected={configControl.border_card_radius == "2px" ? true : false} >2 px</option>
                                <option value={"3px"} selected={configControl.border_card_radius == "3px" ? true : false} >3 px</option>
                                <option value={"4px"} selected={configControl.border_card_radius == "4px" ? true : false} >4 px</option>
                                <option value={"5px"} selected={configControl.border_card_radius == "5px" ? true : false} >5 px </option>
                                <option value={"6px"} selected={configControl.border_card_radius == "6px" ? true : false} >6 px</option>
                                <option value={"7px"} selected={configControl.border_card_radius == "7px" ? true : false} >7 px</option>
                                <option value={"8px"} selected={configControl.border_card_radius == "8px" ? true : false} >8 px</option>
                                <option value={"9px"} selected={configControl.border_card_radius == "9px" ? true : false} >9 px</option>
                                <option value={"10px"} selected={configControl.border_card_radius == "10px" ? true : false} >10 px</option>
                                <option value={"11px"} selected={configControl.border_card_radius == "11px" ? true : false} >11 px</option>
                                <option value={"12px"} selected={configControl.border_card_radius == "12px" ? true : false} >12 px</option>
                                <option value={"13px"} selected={configControl.border_card_radius == "13px" ? true : false} >13 px</option>
                                <option value={"14px"} selected={configControl.border_card_radius == "14px" ? true : false} >14 px</option>
                                <option value={"15px"} selected={configControl.border_card_radius == "15px" ? true : false} >15 px </option>
                                <option value={"16px"} selected={configControl.border_card_radius == "16px" ? true : false} >16 px</option>
                                <option value={"17px"} selected={configControl.border_card_radius == "17px" ? true : false} >17 px</option>
                                <option value={"18px"} selected={configControl.border_card_radius == "18px" ? true : false} >18 px</option>
                                <option value={"19px"} selected={configControl.border_card_radius == "19px" ? true : false} >19 px</option>
                                <option value={"20px"} selected={configControl.border_card_radius == "20px" ? true : false} >20 px</option>
                            </SelectCustom>
                        </ItemSelect>}

                    {configControl.layout_widget != "modelo04" &&
                        <ItemSelect>
                            <label>{"Idioma"}:</label>
                            <SelectCustom name="select_layout_reviews" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { script_public_language: e.target.value } }) }}                        >
                                <option value="" selected={configControl.script_public_language == "" ? true : false}>{translation.thema.boxConfigTema.padrao} app</option>
                                <option value="pt_br" selected={configControl.script_public_language == "pt-br" ? true : false}>português</option>
                                <option value="es" selected={configControl.script_public_language == "es" ? true : false}>{translation.thema.configs.espanhol}</option>
                                <option value="en" selected={configControl.script_public_language == "en" ? true : false}>Inglês</option>
                            </SelectCustom>
                        </ItemSelect>}

                    {configControl.layout_widget == "modelo04" &&
                        <ItemSelect>
                            <label>Largura máxima em px ou porcentagem:</label>
                            <InputCustom
                                type={"text"}
                                value={max_width}
                                placeholder={"1400px ou 90%"}
                                onChange={(e) => {
                                    set_max_width(e.target.value)
                                    //dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_question_config_title_comment: e.target.value } })
                                }}
                            />
                        </ItemSelect>}

                </BoxOptions>

                <ButtonHelp>
                    <img src={IconBossVideo} />
                    <label>{translation.dashboard.precisaAjuda}</label>
                    <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                </ButtonHelp>

                <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />

                <BoxButtons>
                    <ButtonReset onClick={() => getDefaultTheme()}>
                        <label>{translation.thema.boxConfigTema.restaurar}</label>
                        {/* <img src={IconReset} /> */}
                    </ButtonReset>
                    <ButtonSave onClick={() => saveTheme()}>
                        {translation.thema.boxConfigTema.salvar}
                    </ButtonSave>
                </BoxButtons>
            </Container>
        </Main>

    )
}

export default BarConfigProductAjustes